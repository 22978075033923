import React from 'react'
import { Box, Heading, Paragraph, Button } from 'grommet'
import { useResponsive } from '../../hooks'

const UnderlineTextBlock = ({ title, color = 'black' }) => {
  const underlineColor = color === 'black' ? 'brand' : 'white'

  console.log("THIS", title)
  return (
    <Box pad={{ bottom: 'small' }}>
      <Heading
        size="xlarge"
        level={2}
        color={color}
        margin={{ bottom: 'medium', top: 'none' }}
      >
        {title}
      </Heading>
      <Box
        margin={{bottom: "medium"}}
        alignSelf="center"
        width="50%"
        border={{ color: underlineColor, bottom: { size: '1px' } }}
      />
    </Box>
  )
}

export const CenteredTextBlock = ({
  block: {
    align,
    body = null,
    title = null,
    subtitle = null,
    buttonLink,
    greenBackground,
    underlineTitle,
  },
}) => {
  const backgroundColor = greenBackground === 'green' ? 'brand' : 'white'
  const textColor = greenBackground === 'green' ? 'white' : 'black'
  const { isMobile, isDesktop, size } = useResponsive()

  return (
    <Box
      margin={{ horizontal: size === 'large' ? 'large' : null }}
      background={backgroundColor}
      align="center"
      justify="center"
      pad="large"
    >
      {title ? (
        underlineTitle ? (
          <UnderlineTextBlock title={title} color={textColor} />
        ) : (
          <Heading
            color={textColor}
            size="xlarge"
            level={2}
            textAlign="center"
            margin={{ top: 'none', bottom: 'small' }}
          >
            {title}
          </Heading>
        )
      ) : null}
      {subtitle && (
        <Heading
          color={textColor}
          level={4}
          margin="none"
          size="large"
          textAlign="center"
        >
          {subtitle}
        </Heading>
      )}
      {body && (
        <Paragraph
          textAlign="center"
          size={subtitle ? 'medium' : 'large'}
          margin={{ vertical: 'medium' }}
          color={textColor}
        >
          {body}
        </Paragraph>
      )}
      {buttonLink && (
        <Button
          alignSelf={'center'}
          primary
          label={buttonLink.title}
          href={buttonLink.url}
          target={buttonLink.target}
        />
      )}
    </Box>
  )
}
