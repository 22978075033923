import React from 'react'
import { Box, Heading, Paragraph, Button, Grid, Image } from 'grommet'
// import Image from 'gatsby-image'
import parser from 'html-react-parser'
import { useResponsive } from '../../hooks'
import BikeChain from '../../assets/vectors/chain.svg'
import BikeChainStraight from '../../assets/vectors/chain-straight.svg'

import { isDate } from 'moment'

const Icon = ({ src, isMobile = false, width="20%" }) => {
  return (
    <Box
      flex={false}
      width={isMobile ? '100%' : width}
      height={'0'}
      style={{ position: 'relative' }}
      pad={{ bottom: isMobile ? '100%' : width }}
    >
      <Box
        flex={false}
        round="full"
        height="70%"
        width="70%"
        background="#E2F7E1"
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: 'auto auto',
        }}
      >
        <Box
          fill
          align="center"
          justify="center"
          style={{ left: 0, top: 0, position: 'absolute' }}
        >
          <Image
            src={src}
            imgStyle={{ objectFit: 'contain' }}
            style={{ width: '75%', height: '75%' }}
          />
        </Box>
      </Box>
    </Box>
  )
}

const Text = ({ title, body, reverse = false, isMobile, width="20%", noLine=false }) => {
  return (
    <Box
      width={isMobile ? '100%' : width}
      direction={reverse ? 'column' : 'column-reverse'}
    >
      {isMobile || noLine ? (
        <Box height="0px" />
      ) : (
        <Box
          alignSelf="center"
          margin={{ vertical: 'small' }}
          height="25px"
          border={{ size: 'small', color: 'black', side: 'left' }}
        />
      )}
      <Box align="center">
        <Heading
          responsive={false}
          textAlign="center"
          level={4}
          size="large"
          margin="none"
        >
          {title}
        </Heading>
        <Paragraph color="text-weak" textAlign="center">
          {body}
        </Paragraph>
      </Box>
    </Box>
  )
}

const ChainLink = ({ section }) => {
  return (
    <>
      <Box
        margin={{ top: '2%', horizontal: '1%', bottom: '2%' }}
        style={{ position: 'relative' }}
      >
        <BikeChain
          width="98%"
          height="auto"
          style={{ left: '1%', top: '1%', position: 'relative' }}
        />
      </Box>

      <Box
        style={{ position: 'absolute', top: 0 }}
        fill="horizontal"
        height="50%"
        direction="row"
      >
        {section?.map((section, index) => {
          return index % 2 === 1 ? (
            <Text key={index} title={section.title} body={section.body} />
          ) : (
            <Icon key={index} src={section?.icon?.localFile?.publicURL} />
          )
        })}
      </Box>

      <Box
        style={{ position: 'absolute', bottom: 0 }}
        fill="horizontal"
        height="50%"
        direction="row"
      >
        {section?.map((section, index) => {
          return index % 2 === 0 ? (
            <Text key={index} reverse title={section.title} body={section.body} />
          ) : (
            <Icon key={index} src={section?.icon?.localFile?.publicURL} />
          )
        })}
      </Box>
    </>
  )
}

const StraightLink = ({ section }) => {
  return (
    <Box style={{ position: 'relative' }}>
      <Box
        margin={{ top: '2%', horizontal: '1%', bottom: '2%' }}
        style={{ position: 'relative' }}
      >
        <BikeChainStraight
          width="98%"
          height="auto"
          style={{ left: '1%', top: '1%', position: 'relative' }}
        />
        <Box
          style={{ position: 'absolute', top: -10, left: 0 }}
          fill
          justify="between"
          direction="row"

        >
          {section?.map((section, index) => {
            return <Icon key={index} width={"17%"} src={section?.icon?.localFile?.publicURL} />
          })}
        </Box>
      </Box>

      <Box direction="row" gap="large">
        {section?.map((section, index) => {
          return (
            <Box key={index} background="transparent" flex>
              <Text noLine title={section.title} body={section.body} width="100%"/>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export const Chain = ({
  block: { title = null, section = [], type, ctaButton },
  block,
}) => {
  const { isMobile, isDesktop } = useResponsive()

  return (
    <Box
      align="center"
      margin={{ vertical: 'xlarge' }}
      pad={{ horizontal: 'medium' }}
    >
      <Heading level={2} size="large" margin={{ bottom: 'large' }}>
        {parser(title)}
      </Heading>
      <Box style={{ position: 'relative' }} fill="horizontal" >
        {isDesktop &&
          (type === 'straight' ? (
            <StraightLink section={section} />
          ) : (
            <ChainLink section={section} />
          ))}

        {isMobile && (
          <Grid columns={{ count: 2, size: 'auto' }} gap="small">
            {section?.map((section, index) => {
              return (
                <Box key={index}>
                  <Icon src={section?.icon?.localFile?.publicURL} isMobile />
                  <Text
                    reverse
                    title={section.title}
                    body={section.body}
                    isMobile
                  />
                </Box>
              )
            })}
          </Grid>
        )}
      </Box>
      {ctaButton?.url && (
        <Button
          primary
          size="large"
          href={ctaButton.url}
          label={ctaButton.title}
        ></Button>
      )}
    </Box>
  )
}
