import React from 'react'
import { Box, Heading, Paragraph, Button } from 'grommet'
import parse from 'html-react-parser'
import Slider from 'react-slick'
import TitleSwoosh from '../../assets/vectors/swoosh-title.svg'

export const Carousel = ({ block: { title, panel }, block }) => {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
  }

  return (
    <Box justify="center" fill="horizontal">
      {title && (
        <Heading
          color="brand"
          alignSelf="center"
          level={3}
          size="xsmall"
          color="brand"
        >
          {title}
        </Heading>
      )}
      <Slider {...settings}>
        {panel.map((panelContent, index) => {
          return (
            <Box justify="center" align="center" fill="horizontal">
              <Box
                width={{ max: '600px' }}
                alignSelf="center"
                margin="0 auto"
                align="center"
              >
                <TitleSwoosh height="200px" width="100%" />
                {panelContent.title && (
                  <Heading
                    level={3}
                    size="large"
                    textAlign="center"
                    margin="none"
                  >
                    {parse(panelContent.title)}
                  </Heading>
                )}
                {panelContent.subtitle && (
                  <Heading
                    level={4}
                    size="medium"
                    textAlign="center"
                    margin="none"
                  >
                    {panelContent.subtitle}
                  </Heading>
                )}
                <Paragraph textAlign="center">{panelContent.body}</Paragraph>
                {panelContent.buttonLink && (
                  <Button
                    primary
                    label={panelContent.buttonLink.title}
                    href={panelContent.buttonLink.url}
                    target={panelContent.buttonLink.target}
                  />
                )}
              </Box>
            </Box>
          )
        })}
      </Slider>
    </Box>
  )
}
