import React from 'react'
import { Box, Heading, Paragraph } from 'grommet'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Layout } from '../../components/Layout'

import WPGBlocks from 'react-gutenberg'
import GetCustomBlock from '../../blocks'
import parse from 'html-react-parser'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import '../../scss/@wordpress/block-library/build-style/style.css'
import '../../scss/@wordpress/block-library/build-style/theme.css'

//TEMP PROFILES
import SignUpForm from '../../components/Components/SignUpForm'
import { LeftRightImage } from './LeftRightImage'
import { LeftRightGreenImage } from './LeftRightGreenImage'
import { Carousel } from './Carousel'
import { MultipleImageBlock } from './MultipleImageBlock'
import { SwooshCarousel } from './SwooshCarousel'
import { CenteredTextBlock } from './CenteredTextBlock'
import { ProfileBlock } from './ProfileBlock'
import { Chain } from './Chain'
import { SwooshText } from './SwooshText'

const TemporaryBlock = ({ block: { tempContent } }) => {
  return <Box align="center">{parse(tempContent)}</Box>
}

const CustomFieldBlocks = ({ blocks: { layoutBlocks } }) => {
  const renderSwitch = (block, index) => {
    let { fieldGroupName } = block

    console.log("fieldGroupName", fieldGroupName, block);

    switch (fieldGroupName) {
      case 'Page_Contentblocks_LayoutBlocks_LeftRightGreenImage':
        return <LeftRightGreenImage key={index} block={block} />
      case 'Page_Contentblocks_LayoutBlocks_LeftRightWithImage':
        return <LeftRightImage key={index} block={block} />
      case 'Page_Contentblocks_LayoutBlocks_Carousel':
        return <Carousel key={index} block={block} />
      case 'Page_Contentblocks_LayoutBlocks_MultipleImageBlock':
        return <MultipleImageBlock key={index} block={block} />
      case 'Page_Contentblocks_LayoutBlocks_CenteredTextBlock':
        return <CenteredTextBlock key={index} block={block} />
      case 'Page_Contentblocks_LayoutBlocks_SwooshCarousel':
        return <SwooshCarousel key={index} block={block} />
      case 'Page_Contentblocks_LayoutBlocks_TeamProfiles':
        return <ProfileBlock key={index} block={block} />
      case 'Page_Contentblocks_LayoutBlocks_TemporaryBlock':
        return <TemporaryBlock key={index} block={block} />
      case 'Page_Contentblocks_LayoutBlocks_Chain':
        return <Chain key={index} block={block} />
      case 'Page_Contentblocks_LayoutBlocks_Chain':
        return <Chain key={index} block={block} />
      case 'Page_Contentblocks_LayoutBlocks_SwooshText':
        return <SwooshText key={index} block={block}/>
      default:
        return null
    }
  }

  return <>{layoutBlocks && layoutBlocks.map((block, index) => renderSwitch(block, index))}</>
}

export default CustomFieldBlocks
