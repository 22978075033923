import React from 'react'
import { Box, Heading, Paragraph, Button } from 'grommet'
import parse from 'html-react-parser'
import Image from 'gatsby-image'
import Slider from 'react-slick'
import TitleSwoosh from '../../assets/vectors/swoosh-title.svg'
import ArrowLeft from '../../assets/vectors/carousel-arrow.svg'
import ArrowRight from '../../assets/vectors/carousel-arrow-right.svg'
import { isMobile, useResponsive } from '../../hooks'

import { toTitleCase } from '../../helpers'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  const { isMobile } = useResponsive()
  return (
    <ArrowRight
      className={className}
      style={{
        ...style,
        height: isMobile ? 50 : 300,
        width: 60,
        right: -50
      }}
      onClick={onClick}
    />
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  const { isMobile } = useResponsive()

  return (
    <ArrowLeft
      className={className}
      style={{ ...style, height: isMobile ? 50 : 300, width: 60, left: -50 }}
      onClick={onClick}
    />
  )
}

export const SwooshCarousel = ({
  block: { blockTitle, panel, button },
  block,
}) => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 1000,
    // adaptiveHeight: true,
    autoplaySpeed: 4000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  return (
    <Box
      justify="center"
      fill="horizontal"
      pad={{ horizontal: 'xlarge' }}
      margin={{ bottom: 'large' }}
    >
      <Box style={{ position: 'relative' }} justify="center" align="center">
        <TitleSwoosh
          style={{
            position: 'absolute',
            top: '25%',
            bottom: 0,
            margin: 'auto 0',
            zIndex: -1,
          }}
          height="60%"
        />
        {blockTitle && (
          <Heading
            alignSelf="center"
            level={2}
            size="large"
            margin={{ vertical: 'xlarge' }}
          >
            {blockTitle}
          </Heading>
        )}
      </Box>

      <Slider {...settings}>
        {panel.map((panelContent, index) => {
          const imageSrc = {
            fluid: panelContent?.image?.localFile?.childImageSharp?.fluid,
            alt: panelContent?.image?.alt || ``,
          }

          return (
            <Box justify="center" align="center" fill="horizontal" key={index}>
              <Box
                width={{ max: '600px' }}
                alignSelf="center"
                margin="0 auto"
                align="center"
              >
                {panelContent.title && (
                  <Heading
                    level={2}
                    size="large"
                    textAlign="center"
                    margin="none"
                  >
                    {parse(panelContent.title)}
                  </Heading>
                )}
                {panelContent.subtitle && (
                  <Heading
                    level={4}
                    size="medium"
                    textAlign="center"
                    margin="none"
                  >
                    {panelContent.subtitle}
                  </Heading>
                )}
                <Box
                  fill="horizontal"
                  style={{ position: 'relative' }}
                  pad={{ bottom: '65%' }}
                  height="0px"
                  align="center"
                  justify="center"
                >
                  <Image
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      top: 0,
                    }}
                    loading="eager"
                    fluid={imageSrc.fluid}
                    alt={imageSrc.alt}
                    imgStyle={{ objectFit: 'contain' }}
                  />
                </Box>
                <Box>
                  <Paragraph
                    size={panelContent.subtitle ? null : 'large'}
                    textAlign="center"
                    margin={{ top: 'small', bottom: 'medium' }}
                  >
                    {panelContent.body}
                  </Paragraph>
                </Box>
                {panelContent.buttonLink && !button && (
                  <Button
                    size="large"
                    primary
                    label={panelContent.buttonLink.title}
                    href={"#testform"}
                    target={panelContent.buttonLink.target}
                  />
                )}
              </Box>
            </Box>
          )
        })}
      </Slider>
      {button && (
        <Button
          style={{ position: 'relative', top: 0 }}
          alignSelf="center"
          primary
          size="large"
          label={toTitleCase(button.title)}
          href={button.url}
          target={button.target}
        />
      )}
    </Box>
  )
}
