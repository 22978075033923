import React from 'react'
import { Box, Heading, Paragraph, Button } from 'grommet'
import Image from 'gatsby-image'
import { toTitleCase } from '../../helpers'
import { useResponsive } from '../../hooks'
const WhiteBox = ({ children }) => {
  return (
    <Box background="white" pad="large">
      {children}
    </Box>
  )
}

const MainText = ({ title, subtitle, body, buttonLink, color = 'black' }) => {
  return (
    <Box background="transparent">
      {title && (
        <Heading
          size="large"
          level={2}
          margin="none"
          textAlign="start"
          color={color}
        >
          {title}
        </Heading>
      )}
      {subtitle && (
        <Heading
          level={4}
          size="large"
          margin={{ top: 'medium', bottom: 'none' }}
          color={color}
        >
          {subtitle}
        </Heading>
      )}
      {body && (
        <Paragraph
          size={'medium'}
          margin={{ vertical: 'medium' }}
          color={color}
        >
          {body}
        </Paragraph>
      )}
      {buttonLink && (
        <Button
          size="large"
          alignSelf={'start'}
          primary
          label={buttonLink.title}
          href={buttonLink.url}
          target={buttonLink.target}
        />
      )}
    </Box>
  )
}

export const LeftRightGreenImage = ({
  block: {
    align,
    body = null,
    title = null,
    subtitle = null,
    image = {},
    buttonLink = null,
    backgroundImage = false,
    whiteBox = false,
  },
}) => {
  const { isMobile, isDesktop, size } = useResponsive()

  const imageSrc = {
    fluid: image?.localFile?.childImageSharp?.fluid,
    alt: image?.alt || ``,
  }

  //   backgroundImage = false

  return (
    <Box margin={{ horizontal: size === 'large' ? 'large' : null }}>
      <Box
        flex
        margin="large"
        style={{ position: 'relative' }}
        direction={align === 'left' ? 'row' : 'row-reverse'}
        background="brand"
        fill="horizontal"
        alignSelf="center"
        align="center"
        justify="center"
      >
        <Box
          height="0px"
          fill="horizontal"
          pad={{ bottom: isMobile ? '150%' : '65%' }}
          style={{ position: 'relative' }}
        >
          
          {backgroundImage && imageSrc?.fluid && (
            <Image
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
              fluid={imageSrc.fluid}
              alt={imageSrc.alt}
              imgStyle={{ objectFit: 'cover' }}
            />
          )}
        </Box>

        <Box
          height="100%"
          width={isMobile ? '100%' : '50%'}
          pad={{
            left: isMobile ? "small" : 'xlarge',
            vertical: 'none',
            right: backgroundImage ? (isMobile ? 'small' : 'none') : 'xlarge',
          }}
          align="start"
          justify="center"
          style={{
            position: backgroundImage ? 'absolute' : 'relative',
            top: 0,
            left: 0,
          }}
        >
          {whiteBox && backgroundImage ? (
            <WhiteBox>
              <MainText
                title={title}
                subtitle={subtitle}
                body={body}
                buttonLink={buttonLink}
              />
            </WhiteBox>
          ) : (
            <MainText
              color="white"
              title={title}
              subtitle={subtitle}
              body={body}
              buttonLink={buttonLink}
            />
          )}
        </Box>
        {!backgroundImage && imageSrc.fluid && (
          <Box
            width="50%"
            height="100%"
            pad={{ bottom: isMobile ? '75%' : '50%' }}
            flex
            background="red"
            style={{ position: 'relative' }}
          >
            <Box
              fill
              style={{ position: 'absolute', top: 0, left: 0 }}
              background="red"
            >
              <Image
                style={{ height: '100%', width: '100%' }}
                fluid={imageSrc.fluid}
                alt={imageSrc.alt}
                imgStyle={{ objectFit: 'cover' }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}
