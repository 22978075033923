import React from 'react';
import { Box, Heading, Paragraph, Grid } from 'grommet';
import Image from 'gatsby-image';

export const MultipleImageBlock = ({
  block: { align, body = null, title = null, subtitle = null, images },
}) => {
  console.log(images);
  return (
    <Box pad="large" align="center">
      <Box margin={{ bottom: 'large' }} align="center">
        <Heading level={2} size="large" margin="none">
          {title}
        </Heading>
        <Heading level={4} size="small" color="brand" margin="none">
          {subtitle}
        </Heading>
      </Box>

      <Grid gap="large" columns={['flex', 'flex']}>
        {images &&
          images.map(({ image, caption }, index) => {
            const imageSrc = {
              fluid: image.localFile?.childImageSharp?.fluid,
              alt: image.alt || ``,
            };
            return (
              <Box>
                <Box
                  fill="horizontal"
                  background="#e3e3e3"
                  style={{ position: 'relative' }}
                  justify="center"
                  height="0px"
                  pad={{ top: '65%' }}
                >
                  {image && (
                    <div
                      style={{
                        background: '#e3e3e3',
                        position: 'absolute',
                        top: 0,
                        height: '100%',
                        width: '100%',
                      }}
                    >
                      <Image
                        style={{ height: '100%', width: '100%' }}
                        fluid={imageSrc.fluid}
                        alt={imageSrc.alt}
                        imgStyle={{ objectFit: 'cover' }} />
                    </div>
                  )}
                </Box>
                <Paragraph textAlign="center">{caption}</Paragraph>
              </Box>
            );
          })}
      </Grid>
    </Box>
  );
};
