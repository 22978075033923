import React from 'react'
import { Box, Heading, Paragraph, Button } from 'grommet'
import Image from 'gatsby-image'
import { useResponsive } from '../../hooks'
import Swoosh from '../../assets/vectors/swoosh-37.svg'
import SwooshLeft from '../../assets/vectors/swoosh-38.svg'
import parser from 'html-react-parser'


export const LeftRightImage = ({
  block: {
    align,
    body = null,
    title = null,
    subtitle = null,
    image = {},
    buttonLink = null,
    whiteBox = false,
    swoosh = false,
  },
}) => {
  const { isMobile, isDesktop, themeSizes } = useResponsive()

  const imageSrc = {
    fluid: image?.localFile?.childImageSharp?.fluid,
    alt: image?.alt || ``,
  }

  return (
    <Box
      flex
      fill="horizontal"
      style={{ position: 'relative' }}
      direction={
        isMobile ? 'column-reverse' : align === 'left' ? 'row' : 'row-reverse'
      }
      margin={{ bottom: '10px' }}
    >
      <Box
        flex
        width={isMobile ? `calc` : '50%'}
        pad="large"
        align="center"
        justify="center"
      >
        <Box>
          {title && (
            <Heading level={1} size="large" margin="none">
              {title}
            </Heading>
          )}
          {subtitle && (
            <Heading
              level={4}
              margin={{ top: 'medium', bottom: 'none' }}
              size="small"
              color="brand"
            >
              {subtitle}
            </Heading>
          )}
          {body && (
            <Paragraph size={'medium'} margin={{ vertical: 'medium' }}>
              {parser(body)}
            </Paragraph>
          )}
          {buttonLink && (
            <Button
              size="large"
              alignSelf={'start'}
              primary
              label={buttonLink.title}
              href={buttonLink.url}
              target={buttonLink.target}
            />
          )}
        </Box>
      </Box>
      <Box
        margin="large"
        width={isMobile ? `calc(100% - ${themeSizes.large})` : '50%'}
        style={{ position: 'relative' }}
        justify="center"
        height="0px"
        pad={{ top: isMobile ? '75%' : '35%' }}
      >
        {swoosh && (
          <Box
            flex={false}
            style={{
              pointerEvents: "none", 
              zIndex: -1,
              position: 'absolute',
              top: '-15%',
              left: '-15%',
              maxWidth: 'none',
            }}
            height="140%"
            width="140%"
          >
            {align === 'right' ? (
              <Swoosh
                style={{
                  pointerEvents: 'none',
                  width: '80%',
                  height: 'auto',
                  top: '10%',
                  position: 'relative',
                }}
              />
            ) : (
              <SwooshLeft
                style={{ pointerEvents: 'none', width: '100%', height: 'auto' }}
              />
            )}
          </Box>
        )}
        {imageSrc?.fluid && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              height: '100%',
              width: '100%',
            }}
          >
            <Image
              style={{ height: '100%', width: '100%' }}
              fluid={imageSrc.fluid}
              alt={imageSrc.alt}
              imgStyle={{ objectFit: 'cover' }}
            />
          </div>
        )}
      </Box>
    </Box>
  )
}
