import loadable from '@loadable/component'
import React from 'react'

const GreenBackground = loadable(() => import('./GreenBackground'))
const StandardBackground = loadable(() => import('./StandardBackground'))
const SwooshBackground = loadable(() => import('./SwooshBackground'))
const HeadingBlock = loadable(() => import('./HeadingBlock'))

export default function GetCustomBlock(name, props) {
  console.log(name)

  switch (name) {
    case 'core/heading':
      return HeadingBlock
    case 'acf/green-background':
      return GreenBackground
    case 'acf/standard-image':
      return StandardBackground
    case 'acf/image-with-swoosh':
      return SwooshBackground
    default:
      return null
  }
}
