import React from 'react'
import { Paragraph, Grid, Box, Heading } from 'grommet'
import CenterBlock from '../../components/Components/CenterBlock'
import Tim from '../../assets/images/tim.png'
import Steve from '../../assets/images/steve.png'
import Thomas from '../../assets/images/thomas.png'
import Agata from '../../assets/images/agata.jpg'
import { useResponsive } from '../../hooks'
import Image from 'gatsby-image'

import { LinkedIn } from '../../components/Components/Social'

export const TeamPhoto = ({
  image = null,
  name = '',
  title = '',
  linkedIn = null,
}) => {
  return (
    <Box align="center">
      <Box
        pad={{ top: '100%' }}
        style={{
          width: '100%',
          display: 'block',
          backgroundSize: 'cover',
          backgroundImage: `url("${image}")`,
        }}
      ></Box>
      <Heading
        level={5}
        size={'medium'}
        responsive={false}
        margin={{ bottom: 'none' }}
      >
        {name}
      </Heading>
      <Paragraph size={'medium'} margin={{ top: 'xsmall' }}>
        {title}
      </Paragraph>
      {linkedIn && (
        <a href={linkedIn} target={'_blank'} style={{ border: 'none' }}>
          <LinkedIn />
        </a>
      )}
    </Box>
  )
}

export const ProfileBlock = ({
  block: { title, subtitle, body, image = {} },
}) => {
  const { isDesktop } = useResponsive()

  const imageSrc = {
    fluid: image?.localFile?.childImageSharp?.fluid,
    alt: image?.alt || ``,
  }

  return (
    <CenterBlock
      heading={title}
      level={2}
      content={
        <>
          <Paragraph textAlign="center" color="black" margin={{bottom: "large"}}>
            {body}
          </Paragraph>
            {imageSrc?.fluid && (
              <Image
                style={{ height: '100%', width: '100%', maxWidth: '800px' }}
                fluid={imageSrc.fluid}
                alt={imageSrc.alt}
                imgStyle={{ objectFit: 'cover' }}
              />
            )}
        </>
      }
      swoosh
    />
  )
}
