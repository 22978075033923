import { Box, Heading, Button } from 'grommet'
import React from 'react'
import TitleSwoosh from '../../assets/vectors/swoosh-title.svg'

export const SwooshText = ({ block: { text, ctaButton } }) => {
  return (
    <Box
      justify="center"
      fill="horizontal"
      pad={{ horizontal: 'xlarge' }}
      margin={{ bottom: 'large' }}
    >
      <Box style={{ position: 'relative' }} justify="center" align="center">
        <TitleSwoosh
          style={{
            position: 'absolute',
            top: '15%',
            bottom: 0,
            margin: 'auto 0',
            zIndex: -1,
          }}
          height="60%"
        />
        {text && (
          <Heading
            textAlign="center"
            style={{ maxWidth: '680px' }}
            alignSelf="center"
            level={2}
            size="xlarge"
            margin={{ vertical: 'xlarge' }}
          >
            {text}
          </Heading>
        )}
      </Box>
      {ctaButton?.url && (
        <Button
          alignSelf="center"
          primary
          size="large"
          href={ctaButton.url}
          label={ctaButton.title}
        ></Button>
      )}
    </Box>
  )
}
